import {AnimatePresence} from 'framer-motion'
import React, {Children, Suspense, useEffect, useState} from 'react'
import {Route, Routes, useLocation} from 'react-router-dom'
import Layout from '../components/Common/Layout'
import LoadingAnimation from '../components/Common/LoadingAnimation'
import Sidebar from '../components/Common/Sidebar'
import DancerDetails from '../components/Dancer/DancerDetails'
import SchoolDetails from '../components/School/SchoolDetails'
import Page404 from '../pages/404'
import {HasAuth, RequireAuth} from '../utils/RequireAuth'
import Store from '../utils/Store'
import {
  privateRoutes,
  ROUTE_DANCER,
  ROUTE_DETAILS,
  ROUTE_ROOT,
  ROUTE_SCHOOL,
  ROUTE_SIGN_IN,
  routes,
} from './routes'
import {isProduction} from 'lib/const'

const useReloadDataOnLocationChanged = (location: ReturnType<typeof useLocation>) => {
  const [lastFetchAllDat, setLastFetchAllData] = useState(new Date())
  useEffect(() => {
    // route change detected
    // if lastFetchAllData is more than 5 minutes ago, fetch all data
    const now = new Date()
    const diff = now.getTime() - lastFetchAllDat.getTime()
    const seconds = Math.floor(diff / 1000)
    const maxSeconds = isProduction ? 5 * 60 : 5
    if (seconds > maxSeconds) {
      Store.loadAllData()
      setLastFetchAllData(now)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location?.pathname])
}
const AnimatedRouter = () => {
  const loaded = Store.allDataLoaded.use()
  const location = useLocation()
  const locationArr = location.pathname?.split('/') ?? []
  useReloadDataOnLocationChanged(location)
  return <LoadingAnimation h='100vh' />
  return loaded ? (
    <Suspense fallback={<LoadingAnimation h='100vh' />}>
      <AnimatePresence mode='wait'>
        <Routes location={location} key={locationArr[1]}>
          <Route path={ROUTE_ROOT} element={<Layout />}>
            {Children.toArray(
              routes.map(({element, ...routeProps}) => (
                <Route element={<HasAuth>{element}</HasAuth>} {...routeProps} />
              )),
            )}
            {Children.toArray(
              privateRoutes.map(({element, ...privateRouteProps}) => (
                <Route
                  element={
                    <RequireAuth
                      redirectTo={`${ROUTE_SIGN_IN}?redirectTo=${privateRouteProps.path}`}>
                      {element}
                    </RequireAuth>
                  }
                  {...privateRouteProps}
                />
              )),
            )}
            <Route
              path={ROUTE_DETAILS}
              element={
                <RequireAuth redirectTo={`${ROUTE_SIGN_IN}?redirectTo=${ROUTE_DETAILS}`}>
                  <Sidebar />
                </RequireAuth>
              }>
              <Route path={`${ROUTE_DETAILS}${ROUTE_DANCER}`} element={<DancerDetails />} />
              <Route path={`${ROUTE_DETAILS}${ROUTE_SCHOOL}`} element={<SchoolDetails />} />
            </Route>
            <Route path='*' element={<Page404 />} />
          </Route>
        </Routes>
      </AnimatePresence>
    </Suspense>
  ) : (
    <LoadingAnimation h='100vh' />
  )
}

export default AnimatedRouter
